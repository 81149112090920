var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-box" }, [
    _c("div", { staticClass: "list" }, [
      _vm._m(0),
      _c("div", { staticClass: "list-content" }, [
        _c("div", { staticClass: "item" }, [
          _vm._m(1),
          _c("div", { staticClass: "item-content qr-code" }, [
            _c("img", {
              attrs: {
                src:
                  "https://api.qrserver.com/v1/create-qr-code/?size=150×150&data=" +
                  _vm.redirectUrl,
              },
            }),
          ]),
        ]),
        _vm._m(2),
        _vm._m(3),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-bg" }, [
      _c("img", {
        attrs: { src: require("../assets/images/guide_top_bg.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-top" }, [
      _c("i", { staticClass: "first" }),
      _c("span", [_vm._v("长按下方二维码，将图片保存到手机；")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "item-top" }, [
        _c("i", { staticClass: "second" }),
        _c("span", [_vm._v("打开微信，点击“+”号，选择扫一扫；")]),
      ]),
      _c("div", { staticClass: "item-content guide-2" }, [
        _c("img", {
          attrs: { src: require("../assets/images/wechat_guide_2.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "item-top" }, [
        _c("i", { staticClass: "third" }),
        _c("span", [
          _vm._v(
            "点击右下角“相册”选项，从相册中选取在第一步中保存的二维码图片；"
          ),
        ]),
      ]),
      _c("div", { staticClass: "item-content guide-2" }, [
        _c("img", {
          attrs: { src: require("../assets/images/wechat_guide_3.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }