<template>
	<div class="page-box">
		<!-- <h1>截图保存二维码，并在微信中识别该二维码</h1>
		<img :src="`https://api.qrserver.com/v1/create-qr-code/?size=150×150&data=${redirectUrl}`" /> -->
		<div class="list">
			<div class="top-bg">
				<img src="../assets/images/guide_top_bg.png" />
			</div>
			<div class="list-content">
				<div class="item">
					<div class="item-top">
						<i class="first" />
						<span>长按下方二维码，将图片保存到手机；</span>
					</div>
					<div class="item-content qr-code">
						<img :src="`https://api.qrserver.com/v1/create-qr-code/?size=150×150&data=${redirectUrl}`" />
					</div>
				</div>
				<div class="item">
					<div class="item-top">
						<i class="second" />
						<span>打开微信，点击“+”号，选择扫一扫；</span>
					</div>
					<div class="item-content guide-2">
						<img src="../assets/images/wechat_guide_2.png" />
					</div>
				</div>
				<div class="item">
					<div class="item-top">
						<i class="third" />
						<span>点击右下角“相册”选项，从相册中选取在第一步中保存的二维码图片；</span>
					</div>
					<div class="item-content guide-2">
						<img src="../assets/images/wechat_guide_3.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'weChatGuide',
	data () {
		return {
			redirectUrl: ''
		};
	},
	created () {
		if (this.$route.query.redirectUrl) this.redirectUrl = this.$route.query.redirectUrl;
	}
};
</script>
<style lang="less" scoped>
	.page-box {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.list {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			// height: 100%;
			overflow: auto;
			flex: 1;
			padding-bottom: 19px;
			.top-bg {
				width: 100%;
				height: 59px;
				display: flex;
				img {
					width: 100%;
				}
			}
			.list-content {
				display: flex;
				flex-direction: column;
			}
			.item {
				display: flex;
				flex-direction: column;
				margin-top: 19px;
				.item-top {
					display: flex;
					padding: 0 19px;
					i {
						width: 24px;
						height: 19px;
						display: block;
					}
					i.first {
						background: url(../assets/images/guide_1_icon.png) no-repeat center center;
						background-size: 100% auto;
					}
					i.second {
						background: url(../assets/images/guide_2_icon.png) no-repeat center center;
						background-size: 100% auto;
					}
					i.third {
						background: url(../assets/images/guide_3_icon.png) no-repeat center center;
						background-size: 100% auto;
					}
					span {
						flex: 1;
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #232830;
						line-height: 22px;
						margin-left: 4px;
					}
				}
				.item-content {
					padding: 0 47px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 19px;
				}
				.qr-code {
					img {
						width: 91px;
					}
				}
				.guide-2 {
					img {
						width: 267px;
					}
				}
			}
		}
	}
</style>